<template>
  <section id="PageSingleSeason" class="col-12 wrapper-text">
    <BioPerson :person="person"></BioPerson>
    <ImagePerson :person="person" :personImage="personImage"></ImagePerson>
    <RolePlayPerson :person="person" :role="rolePlayPerson"></RolePlayPerson>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSinglePerson",
  components: {
    'RolePlayPerson': defineAsyncComponent(() =>
        import('@/components/section/RolePlayPerson')
    ),
    'BioPerson': defineAsyncComponent(() =>
        import('@/components/section/BioPerson')
    ),
    'ImagePerson': defineAsyncComponent(() =>
        import('@/components/section/ImagePerson')
    )
  },
  // watch: {
  //   '$route.params.id': function (id) {
  //     if(this.$route.params.id){
  //       this.getPerson(id)
  //     }
  //   }
  // },
  computed: {
    person() {
      return this.$store.state.getMovie.person;
    },
    personImage() {
      return this.$store.state.getMovie.personImage;
    },
    rolePlayPerson() {
      return this.$store.state.getMovie.rolePlayPerson;
    },
  },
  methods: {
    getPerson(id) {
      let query = `/person/${id}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setPerson",
      });
    },
    getPersonImage(id) {
      let query = `/person/${id}/images?api_key=${this.$store.state.getMovie.api_key}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setPersonImage",
      });
    },
    getRolePlayPerson(id) {
      let query = `/person/${id}/movie_credits?api_key=${this.$store.state.getMovie.api_key}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setRolePlayPerson",
      });
    },
  },
  mounted() {
    this.getPerson(this.$route.params.id);
    this.getPersonImage(this.$route.params.id);
    this.getRolePlayPerson(this.$route.params.id);
  },
};
</script>

<style scoped></style>
